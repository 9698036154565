import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import Vuex from 'vuex'
import { $axios } from '~/utils/api'
import {
  auction,
  auctionSearchItem,
  ServerResponseAuction,
  searchParams,
} from '~/@types/auction'

const store = new Vuex.Store<any>({})

export interface AuctionState {
  upcoming: auction[]
}

export const apiParams = (params: any, limit: number) => {
  const { genre1, genre2, genre3, genre4 } = params
  const ids = [].concat(genre1, genre2, genre3, genre4)

  return {
    cursor: params.cursor,
    description: params.description,
    search_word: params.search_word,
    size_unit: params.sizeUnit,
    min_height: params.heightMin,
    max_height: params.heightMax,
    min_width: params.widthMin,
    max_width: params.widthMax,
    estimate_price_lower_bound: params.estimatePriceFrom,
    estimate_price_upper_bound: params.estimatePriceTo,
    contract_price_lower_bound: params.priceFrom,
    contract_price_upper_bound: params.priceTo,
    auction_house_ids: params.auctionHouseIds,
    lot: params.lot,
    auction_holding_date_from: params.timeFrom,
    auction_holding_date_to: params.timeEnd,
    genre_ids: ids.length ? ids : null,
    limit: limit > 10 ? 10 : limit,
    result_display_order_id: params.order,
  }
}

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'auction',
  store,
  dynamic: true,
})
export default class Auction extends VuexModule implements AuctionState {
  upcoming: auction[] = []
  searchResults: auctionSearchItem[] = []
  params: searchParams = {} as searchParams
  remainCnt: any = null
  resultNum: any = null
  suggestWords: Array<[]> = []
  hasFirstSearch: Boolean = false

  @Mutation
  setAuctionUpcoming(data: auction[]) {
    this.upcoming = data
  }

  @Action({ rawError: true, commit: 'setAuctionUpcoming' })
  async getAuctionUpcoming({ api, limit }: { api: string; limit: string }) {
    try {
      const keyword = 'limit=' + limit
      const getUrl = encodeURI(process.env.API_BASE_URL + api + keyword)
      const response = await $axios
        .get(getUrl, {
          withCredentials: true,
        })
        .then((res: ServerResponseAuction) => {
          return res.data.auctions
        })
      return response
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  setParams(params: any) {
    this.params = { ...this.params, ...params }
  }

  @Action({ rawError: true, commit: 'setParams' })
  updateParams(params: any) {
    return params
  }

  @Mutation
  setAuctionSearch(response: any) {
    if (!this.hasFirstSearch) {
      this.hasFirstSearch = true
    }
    if (!response) {
      this.searchResults = []
      return
    }

    if (response.params) {
      this.params = response.params
      this.searchResults.push(response.data.items)
      this.searchResults = this.searchResults.flat()
    } else {
      this.searchResults = this.searchResults.concat(response.data.items)
    }

    this.params.cursor = response.data.cursor
  }

  @Action({ rawError: true, commit: 'setAuctionSearch' })
  async search(params: any) {
    try {
      const apiBaseUrl = process.env.API_BASE_URL
        ? encodeURI(process.env.API_BASE_URL + 'items/search')
        : ''
      const temp = params ?? this.params
      const response = await $axios.get(apiBaseUrl, {
        params: apiParams(temp, temp.limit),
      })

      this.setResultNum(response)

      return { data: response.data, params }
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  // @Mutation
  // setSuggestWord(response: any) {
  //   if (!response) {
  //     this.suggestWords = []
  //     return
  //   }
  //   const elasticWords = response.data.search_suggest_word
  //   const searchLogs = response.data.search_word_logs
  //   this.suggestWords = elasticWords.concat(searchLogs)
  // }

  @Action({ rawError: true })
  async getSuggestWords(params: any) {
    try {
      const apiBaseUrl = process.env.API_BASE_URL
        ? encodeURI(process.env.API_BASE_URL + 'search_word_logs')
        : ''

      const response = await $axios.get(apiBaseUrl, {
        params: { search_word: params },
      })

      // console.log(response)

      // this.setSuggestWord(response)
      return { data: response.data }
    } catch (e) {
      console.log(e)
    }
  }

  @Mutation
  setRemainCnt(response: any) {
    this.remainCnt = response.data.count
  }

  @Action({ rawError: true, commit: 'setRemainCnt' })
  async getRemainCnt() {
    try {
      const apiBaseUrl = process.env.API_BASE_URL
        ? encodeURI(process.env.API_BASE_URL + 'items/search_remaining')
        : ''
      const response = await $axios.get(apiBaseUrl)

      return { data: response.data }
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  @Mutation
  setResultNum(response: any) {
    this.resultNum = response.data.total_count
  }

  @Action({ rawError: true, commit: 'setResultNum' })
  async getResultNum(params: any) {
    try {
      const apiBaseUrl = process.env.API_BASE_URL
        ? encodeURI(process.env.API_BASE_URL + 'items/search_results_num')
        : ''
      const response = await $axios.get(apiBaseUrl, {
        params,
      })
      return { data: response.data, params }
    } catch (e) {
      console.log(e)
    }
  }
}
